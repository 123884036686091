import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const TopicTemplate = ({ data }) => {
  const topic = data.microcmsTopics;
  return (
    <Layout>
      <Seo title={topic.title} />
      <div className="flex flex-col justify-center items-center p-5 md:p-20 bg-gray-50">
        <h1 className="sm:text-3xl text-2xl font-medium text-gray-600 mb-2">
          {topic.title}
        </h1>
        <span>{topic.publishedAt}</span>
      </div>
      <div className="container mx-auto max-w-4xl px-5 md:px-10 py-10 md:py-20 leading-relaxed space-y-5">
        <div
          className="microcms"
          dangerouslySetInnerHTML={{ __html: topic.content }}
        ></div>
        <div className="flex justify-center">
          <Link
            to="/topics"
            className="border border-steel-500 text-steel-500 px-3 py-2 hover:bg-steel-100 rounded-sm font-sans"
          >
            トピックス一覧
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default TopicTemplate;

export const query = graphql`
  query TopicDetail($id: String!) {
    microcmsTopics(id: { eq: $id }) {
      topicsId
      title
      publishedAt(formatString: "YYYY-MM-DD")
      content
    }
  }
`;
